import apiServices from './apiServices';
import { getAvailableReq,setAvailableReq }  from './dtos';



class availServices  {
    

     baseSvc = apiServices;
     client = this.baseSvc.client;

   
    constructor() {
     
      }

      

      setavailability(availability_date:string,availability_type:string)
      {
        
        const request = new setAvailableReq();
        request.availability_date = availability_date;
        request.availability_type = availability_type;

        return this.client.put(request);

      }
      
    getAvailability()
    {
        
        
        const request = new getAvailableReq();

        return this.client.get(request);


    }
    

}




export default availServices;
