import { JsonServiceClient } from '@servicestack/client';
import { Authenticate, registerMemberReq } from './dtos';

class ApiServices {
  public client: JsonServiceClient;
  static loggedIn: boolean;

  constructor() {
    ApiServices.loggedIn = !!localStorage.getItem('USER_AUTHENTICATED');

    this.client = new JsonServiceClient(this.apiUrl());
    this.client.useTokenCookie = true;

    this.client.onAuthenticationRequired = async () => {
      ApiServices.loggedIn = false;
      window.location.href = '/login';
    };
  }

  public hasHole = (roleName: string): boolean => {
    var rolesJson = localStorage.getItem('JsonServiceClient_roles');
    if (!rolesJson) return false;

    var roles = JSON.parse(rolesJson);
    if (roles && roles.indexOf(roleName) > -1) {
      return true;
    }

    return false;
  };

  public apiUrl = () => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
       return "https://localhost:8443";
      return 'https://iatsetest-g9dsbmfeeee3ercs.westus-01.azurewebsites.net/';
    }
    return '';
  };

  parseJwt(token: string) {
    if (!token || token.split('.').length < 2) {
      return null;
    }
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  }

  public isloggedin = () => {
    return ApiServices.loggedIn;
  };

  async logout() {
    localStorage.setItem('JsonServiceClient_roles', '[]');

    const req = new Authenticate();
    req.provider = 'logout';

    ApiServices.loggedIn = false;

    return this.client.post(req);
  }

  async register(
    email: string,
    password: string,
    invite_code: string,
    verification_code: string,
    captcha_value: string
  ) {
    this.logout();

    const request = new registerMemberReq();
    request.email = email;
    request.pwd = password;
    request.invite_code = invite_code;
    request.verification_code = verification_code;
    request.captcha_value = captcha_value;

    return this.client.post(request).then(() => {
      this.login(email, password);
    });
  }

  async login(userName: string, password: string) {
    const authResponse = await this.client.post(
      new Authenticate({
        provider: 'credentials',
        userName,
        password,
      })
    );
    localStorage.setItem('USER_AUTHENTICATED', JSON.stringify(true));
    localStorage.setItem(
      'JsonServiceClient_roles',
      JSON.stringify(authResponse.roles)
    );
    ApiServices.loggedIn = true;

    return authResponse;
  }
}

const apiService = new ApiServices();
export default apiService;
